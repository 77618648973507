export const deliveryTypes = {
  default_delivery: 'Delivery',
  customer_pick_up: 'Pick Up',
  ship: 'Shipping',
  custom_delivery: 'Delivery',
};
export const deliveryMethods = {
  default_delivery: 'Delivery',
  customer_pick_up: 'Customer Pick Up',
  ship: 'Shipping',
  custom_delivery: 'Delivery',
};
export const deliveryTimes = {
  morning: 'Morning',
  evening: 'Evening',
  clock: 'Specific Time',
};
export const deliverySettingTypes = {
  default_delivery: 'Delivery',
  customer_pick_up: 'Customer Pick Up',
  ship: 'Shipping',
};
export const customerPickupLocationTypes = {
  location: 'Location Address',
  warehouse: 'Warehouse Address',
};
export const customerDeliveryTypes = {
  customer_pick_up: 'Pick Up',
  custom_delivery: 'Delivery',
  default_delivery: 'Delivery',
};
export const checkingInDeliveryTypes = {
  default_delivery: 'Delivery',
  customer_pick_up: 'Customer Drop Off',
};

export const npsColors = [
  '#D3347F',
  '#D83553',
  '#D63744',
  '#DB4740',
  '#E55E4A',
  '#FF886D',
  '#FB9161',
  '#FFA96B',
  '#D7B36F',
  '#9BBA70',
  '#54C06E',
];

export const productTypeToUrl = {
  items: 'items',
  accessories: 'accessories',
  addons: 'purchases',
  bundles: 'bundles',
};

export const toolbarConfig = {
  // Optionally specify the groups to display (displayed in the order listed).
  display: [
    'INLINE_STYLE_BUTTONS',
    'BLOCK_TYPE_BUTTONS',
    'BLOCK_TYPE_DROPDOWN',
    'HISTORY_BUTTONS',
  ],
  INLINE_STYLE_BUTTONS: [
    { label: 'Bold', style: 'BOLD' },
    { label: 'Italic', style: 'ITALIC' },
    { label: 'Underline', style: 'UNDERLINE' },
  ],
  BLOCK_TYPE_DROPDOWN: [
    { label: 'Normal', style: 'unstyled' },
    { label: 'Heading Large', style: 'header-one' },
    { label: 'Heading Medium', style: 'header-two' },
    { label: 'Heading Small', style: 'header-three' },
  ],
  BLOCK_TYPE_BUTTONS: [
    { label: 'UL', style: 'unordered-list-item' },
    { label: 'OL', style: 'ordered-list-item' },
  ],
};

export const exportFields = (softwareTier = 'all', terminologies = {}) => {
  const { purchaseSingular, purchasePlural } = terminologies;
  const rental = Object.assign(
    softwareTier === 'all'
      ? {
          source: {
            type: 'string',
            prettyName: 'Order Source',
          },
          referral_source: {
            type: 'string',
            prettyName: 'Referral Source',
          },
          name: {
            type: 'string',
            prettyName: 'Order Name',
          },
          token: { type: 'string', prettyName: 'Order ID' },
          quote_date: { type: 'date', prettyName: 'Quote Date' },
          reservation_date: { type: 'date', prettyName: 'Reservation Date' },
          closed_date: { type: 'date', prettyName: 'Closed Date' },
          event_start_date_time: {
            type: 'date',
            prettyName: 'Order Start Date',
          },
          team_members_names: {
            type: 'string',
            prettyName: 'Team Members',
          },
          contact_category: {
            type: 'string',
            prettyName: 'Contact Category',
          },
          event_end_date_time: { type: 'date', prettyName: 'Order End Date' },

          status: { type: 'string', prettyName: 'Order Status' },
          rental_item_total_before_discount: {
            type: 'number',
            prettyName: 'Item Total',
          },
          rental_add_on_total: {
            type: 'number',
            prettyName: `${purchaseSingular} Total`,
          },
          inventory_total: { type: 'number', prettyName: 'Inventory Total' },
          other_fee_total: {
            type: 'number',
            prettyName: 'Fees Total (excluding damage waiver fee / CC fees)',
          },

          discount_total: { type: 'number', prettyName: 'Discounts Total' },
          expense_total: { type: 'number', prettyName: 'Expense Total' },
          sub_total: { type: 'number', prettyName: 'Sub Total' },
          tax_total: { type: 'number', prettyName: 'Tax Total' },
          total_tax_rate: { type: 'number', prettyName: 'Tax Rate Total' },
          sub_total_for_tax: { type: 'number', prettyName: 'Taxable Amount' },
          overall_total: { type: 'number', prettyName: 'Total' },
          amount_remaining: {
            type: 'number',
            prettyName: 'Balance Remaining',
          },
          credit_card_fee_total: {
            type: 'number',
            prettyName: 'Credit Card Fee',
          },
          customer_names: { type: 'string', prettyName: 'Customer' },
          start_date: { type: 'date', prettyName: 'Check Out Date' },
          end_date: { type: 'date', prettyName: 'Check In Date' },
          delivery_type: { type: 'string', prettyName: 'Delivery Type' },
          total_delivery_cost: { type: 'number', prettyName: 'Delivery Fee' },
          export_expense_data: { type: 'refund', prettyName: 'Expenses' },
          export_discount_data: { type: 'discount', prettyName: 'Discounts' },
          export_fee_data: { type: 'fee', prettyName: 'Fees' },

          damage_waiver_fee_total: {
            type: 'number',
            prettyName: 'Damage Waiver Fee',
          },
          company_name: { type: 'string', prettyName: 'Company' },
          tax_exempt: { type: 'string', prettyName: 'Tax Exempt' },
          rental_bundle_total: { type: 'number', prettyName: 'Bundle Total' },
          location: { type: 'string', prettyName: 'Location' },
          primary_contact: { type: 'string', prettyName: 'Primary Contact' },
          delivery_address_locale: {
            type: 'string',
            prettyName: 'Delivery State',
          },
          delivery_address_city: {
            type: 'string',
            prettyName: 'Delivery City',
          },
          staff_info: {
            type: 'string',
            prettyName: 'Staff Info',
          },
          staff_amounts: {
            type: 'string',
            prettyName: 'Staff Amounts',
          },
        }
      : {
          source: {
            type: 'string',
            prettyName: 'Order Source',
          },
          name: {
            type: 'string',
            prettyName: 'Order Name',
          },
          contact_category: {
            type: 'string',
            prettyName: 'Contact Category',
          },
          token: { type: 'string', prettyName: 'Order ID' },
          quote_date: { type: 'date', prettyName: 'Quote Date' },
          reservation_date: { type: 'date', prettyName: 'Reservation Date' },
          closed_date: { type: 'date', prettyName: 'Closed Date' },
          event_start_date_time: {
            type: 'date',
            prettyName: 'Order Start Date',
          },
          event_end_date_time: { type: 'date', prettyName: 'Order End Date' },

          status: { type: 'string', prettyName: 'Order Status' },
          fee_total_excluding_damage_waiver: {
            type: 'number',
            prettyName: 'Fees',
          },

          discount_total: { type: 'number', prettyName: 'Discounts' },
          expense_total: { type: 'number', prettyName: 'Expense Total' },
          sub_total: { type: 'number', prettyName: 'Sub Total' },
          tax_total: { type: 'number', prettyName: 'Tax' },
          amount_remaining: {
            type: 'number',
            prettyName: 'Remaining Balance',
          },
          credit_card_fee_total: {
            type: 'number',
            prettyName: 'Credit Card Fee',
          },
          export_expense_data: { type: 'refund', prettyName: 'Expenses' },
          export_discount_data: { type: 'discount', prettyName: 'Discounts' },
          export_fee_data: { type: 'fee', prettyName: 'Fees' },
          customer_names: { type: 'string', prettyName: 'Customer' },
          tax_exempt: { type: 'string', prettyName: 'Tax Exempt' },
          rental_bundle_total: { type: 'number', prettyName: 'Bundle Total' },
          staff_info: {
            type: 'string',
            prettyName: 'Staff Info',
          },
          staff_amounts: {
            type: 'string',
            prettyName: 'Staff Amounts',
          },
        }
  );
  return {
    rental: rental,
    transaction: {
      rental_name: { type: 'string', prettyName: 'Order Name' },
      rental_token: { type: 'string', prettyName: 'Order ID' },
      rental_closed_date: { type: 'date', prettyName: 'Order Closed Date' },
      rental_start_date: { type: 'date', prettyName: 'Order Start Date' },
      rental_end_date: { type: 'date', prettyName: 'Order End Date' },
      rental_status: { type: 'string', prettyName: 'Order Status' },
      payment_date: { type: 'date', prettyName: 'Transaction Date' },
      payment_info: { type: 'string', prettyName: 'Payment Info' },
      amount: { type: 'number', prettyName: 'Amount' },
      processing_fee: { type: 'number', prettyName: 'Processing Fee' },
      net_earnings: { type: 'number', prettyName: 'Net Earnings' },
      amount_refunded: { type: 'number', prettyName: 'Refunded Amount' },
      export_refund_data: { type: 'refund', prettyName: 'Refunds' },
      token: { type: 'string', prettyName: 'Transaction ID' },
      payment_type: { type: 'string', prettyName: 'Payment Type' },
      status: { type: 'string', prettyName: 'Payment Status' },
      customer_names: { type: 'string', prettyName: 'Customer Name' },
      company_name: { type: 'string', prettyName: 'Company Name' },
      tax_exempt: { type: 'string', prettyName: 'Tax Exempt' },
      location: { type: 'string', prettyName: 'Location' },
    },
    inventory: {
      name: { type: 'string', prettyName: 'Name' },
      serialization_status: { type: 'string', prettyName: 'Inventory Type' },
      rental_count: { type: 'string', prettyName: 'Number of Times Ordered' },
      hourly_price: { type: 'string', prettyName: 'Hourly Price' },
      half_day_price: { type: 'string', prettyName: 'Half-Day Price' },
      daily_price: { type: 'string', prettyName: 'Daily Price' },
      weekly_price: { type: 'string', prettyName: 'Weekly Price' },
      monthly_price: { type: 'string', prettyName: 'Monthly Price' },
      // flat_price: { type: "string", prettyName: "Flat Price" },
      // fixed_price: { type: "string", prettyName: "Fixed Price" },
      pricing: { type: 'string', prettyName: 'Pricing' },
      purchase_price: { type: 'string', prettyName: 'Wholesale Cost' },
      rental_price: { type: 'string', prettyName: 'Order Price' },
      departments: { type: 'string', prettyName: 'Departments' },
      primary_photo_url: { type: 'string', prettyName: 'Primary Photo URL' },
      sf_sub_categories: { type: 'string', prettyName: 'Tags' },
      description: { type: 'string', prettyName: 'Description' },
      turnover_time: { type: 'string', prettyName: 'Turnover Time' },
      turnover_unit: { type: 'string', prettyName: 'Turnover Unit' },
      weight: { type: 'string', prettyName: 'Weight' },
      length_ft: { type: 'string', prettyName: 'Length (ft)' },
      length: { type: 'string', prettyName: 'Length (in)' },
      width_ft: { type: 'string', prettyName: 'Width (ft)' },
      width: { type: 'string', prettyName: 'Width (in)' },
      height_ft: { type: 'string', prettyName: 'Height (ft)' },
      height: { type: 'string', prettyName: 'Height (in)' },
      quantity: { type: 'string', prettyName: 'Quantity' },
      color_names: { type: 'string', prettyName: 'Colors' },
      material: { type: 'string', prettyName: 'Material' },
      warehouse_location: { type: 'string', prettyName: 'Warehouse Location' },
      location: { type: 'string', prettyName: 'Location' },
      id: {
        type: 'string',
        prettyName: 'Inventory ID',
      },
      bundle_items: {
        type: 'string',
        prettyName: 'Bundled Items (bundles only)',
      },
      bundle_accessories: {
        type: 'string',
        prettyName: 'Bundled Accessories (bundles only)',
      },
      bundle_purchases: {
        type: 'string',
        prettyName: `Bundled ${purchasePlural} (bundles only)`,
      },
      usage: {
        type: 'string',
        prettyName: 'Usage',
      },
      taxes: {
        type: 'string',
        prettyName: 'Taxes',
      },
      tax_exempt: { type: 'string', prettyName: 'Tax Exempt' },
      damage_waiver_exempt: {
        type: 'string',
        prettyName: 'Damage Waiver Exempt',
      },
    },
    serialized: {
      custom_id: {
        type: 'string',
        prettyName: 'Custom ID',
      },
      rental_count: {
        type: 'string',
        prettyName: 'Number of Times Ordered',
      },
      in_maintenance: {
        type: 'string',
        prettyName: 'In Maintenance',
      },
      in_use: {
        type: 'string',
        prettyName: 'In Use',
      },
      date_added: {
        type: 'string',
        prettyName: 'Date Added',
      },
      last_modified: {
        type: 'string',
        prettyName: 'Last Modified',
      },
    },
  };
};

export const exportFilterDates = (softwareTier = 'all') => {
  if (softwareTier === 'all') {
    return {
      event_start_date_time: 'Order Start Date',
      event_end_date_time: 'Order End Date',
      quote_date: 'Quote Date',
      reservation_date: 'Reservation Date',
      closed_date: 'Closed Date',
      start_date: 'Check Out Date',
      end_date: 'Check In Date',
    };
  } else {
    return {
      event_start_date_time: 'Order Start Date',
      event_end_date_time: 'Order End Date',
      quote_date: 'Quote Date',
      reservation_date: 'Reservation Date',
      closed_date: 'Closed Date',
    };
  }
};

export const displayInventoryType = {
  items: 'Items',
  purchases: 'Purchases',
  accessories: 'Accessories',
  bundles: 'Bundles',
};

export const exportPaymentStatus = {
  None: 'None',
  Partial: 'Partial',
  Full: 'Full',
};

export const permissionSections = (terminologies) => {
  const { purchasePlural } = terminologies;

  return [
    {
      prettyName: 'Contacts',
      keyName: 'contacts',
      permissions: [
        { key: 'view_contacts', prettyName: 'View Contacts' },
        {
          key: 'edit_add_contacts',
          prettyName: 'View/Edit/Add Contacts',
          forbiddenRoles: ['devops'],
        },
        {
          key: 'add_remove_discounts',
          prettyName: 'Add/Remove Discounts',
          isAdvancedPermission: true,
          groupKey: 'contacts',
        },
        {
          key: 'add_remove_credit_cards',
          prettyName: 'Add/Remove Credit Cards',
          isAdvancedPermission: true,
          groupKey: 'contacts',
        },
        {
          key: 'add_remove_documents',
          prettyName: 'Add/Remove Documents',
          isAdvancedPermission: true,
          groupKey: 'contacts',
        },
        {
          key: 'add_new_contacts',
          prettyName: 'Add New Contacts (Customers, Companies, Venues)',
          isAdvancedPermission: true,
          parentKey: 'edit_add_contacts',
          groupKey: 'contacts',
        },
        {
          key: 'edit_contact_name',
          prettyName: 'Edit Contact Name (Customers, Companies, Venues)',
          isAdvancedPermission: true,
          parentKey: 'edit_add_contacts',
          groupKey: 'contacts',
        },
        {
          key: 'edit_contact_phone',
          prettyName: 'Edit Contact Phone (Customers, Companies, Venues)',
          isAdvancedPermission: true,
          parentKey: 'edit_add_contacts',
          groupKey: 'contacts',
        },
        {
          key: 'edit_contact_email',
          prettyName: 'Edit Contact Email (Customers, Companies, Venues)',
          isAdvancedPermission: true,
          parentKey: 'edit_add_contacts',
          groupKey: 'contacts',
        },
        {
          key: 'edit_contact_address',
          prettyName: 'Edit Contact Address (Customers Companies, Venues)',
          isAdvancedPermission: true,
          parentKey: 'edit_add_contacts',
          groupKey: 'contacts',
        },
        {
          key: 'edit_venues',
          prettyName: 'Edit Venues',
          isAdvancedPermission: true,
          groupKey: 'contacts',
        },
        {
          key: 'view_edit_customer_payment_settings',
          prettyName: 'View/Edit Customer Payment Settings (Deposit Amount)',
          isAdvancedPermission: true,
          groupKey: 'contacts',
        },
        {
          key: 'view_edit_company_payment_limits',
          prettyName:
            'View/Edit Payment Limits, Terms, Invoice Dates & Statement Info',
          isAdvancedPermission: true,
          groupKey: 'contacts',
        },
        {
          key: 'modify_tax_exemption_status',
          prettyName: 'Modify Tax Exemption Status',
          isAdvancedPermission: true,
          groupKey: 'contacts',
        },
        {
          key: 'link_companies_customers',
          prettyName: 'Link Companies and Customers',
          isAdvancedPermission: true,
          groupKey: 'contacts',
        },
        {
          key: 'add_commissioned_customer_to_company',
          prettyName: 'Add Commissioned Customer to Company',
          isAdvancedPermission: true,
          groupKey: 'contacts',
        },
        {
          key: 'add_edit_account_owner',
          prettyName: 'Add/Edit Account Owner',
          isAdvancedPermission: true,
          groupKey: 'contacts',
        },
        {
          key: 'add_remove_child_company',
          prettyName: 'Add/Remove Child Company from Company',
          isAdvancedPermission: true,
          groupKey: 'contacts',
        },
        {
          key: 'edit_company_credit_limit',
          prettyName: 'Edit Company Credit Limit',
          isAdvancedPermission: true,
          groupKey: 'contacts',
        },
      ],
    },
    {
      prettyName: 'Multilocation',
      keyName: 'multilocation',
      permissions: [
        {
          key: 'view_edit_ml_settings',
          prettyName: 'Settings',
          forbiddenRoles: ['devops'],
        },
        {
          key: 'view_edit_ml_account',
          prettyName: 'Account',
          forbiddenRoles: ['devops'],
        },
        {
          key: 'view_edit_ml_team',
          prettyName: 'Add Users',
          forbiddenRoles: ['devops'],
        },
        {
          key: 'view_edit_ml_locations',
          prettyName: 'Locations',
          forbiddenRoles: ['devops'],
        },
        // advanced
        {
          key: 'access_to_team_multilocation',
          prettyName: 'Access To Multilocation: Team',
          isAdvancedPermission: true,
          groupKey: 'multilocation',
          forbiddenRoles: ['devops'],
        },
      ],
    },
    {
      prettyName: 'Inventory',
      keyName: 'inventory',
      permissions: [
        { key: 'view_inventory', prettyName: 'View Inventory' },
        {
          key: 'edit_inventory_ledger',
          prettyName: 'Inventory Ledger',
          isAdvancedPermission: true,
          parentKey: 'view_inventory',
          groupKey: 'inventory',
        },
        {
          key: 'bulk_actions_inventory',
          prettyName: `Bulk Actions on Items, Accessories, ${purchasePlural} & Bundles`,
          isAdvancedPermission: true,
          parentKey: 'view_inventory',
          groupKey: 'inventory',
        },
        {
          key: 'view_inventory_count',
          prettyName: 'Access to Inventory: Inventory Count',
          isAdvancedPermission: true,
          parentKey: 'view_inventory',
          groupKey: 'inventory',
        },
        {
          key: 'edit_add_inventory',
          prettyName: 'Edit/Add Inventory',
          forbiddenRoles: ['devops'],
          parentKey: 'view_inventory',
          groupKey: 'inventory',
        },
        {
          key: 'edit_items',
          prettyName: 'Edit Items',
          forbiddenRoles: ['devops'],
          isAdvancedPermission: true,
          parentKey: 'edit_add_inventory',
          groupKey: 'inventory',
        },
        {
          key: 'edit_accessories',
          prettyName: 'Edit Accessories',
          forbiddenRoles: ['devops'],
          isAdvancedPermission: true,
          parentKey: 'edit_add_inventory',
          groupKey: 'inventory',
        },
        {
          key: 'edit_purchases',
          prettyName: `Edit ${purchasePlural}`,
          forbiddenRoles: ['devops'],
          isAdvancedPermission: true,
          parentKey: 'edit_add_inventory',
          groupKey: 'inventory',
        },
        {
          key: 'edit_bundles',
          prettyName: 'Edit Bundles',
          forbiddenRoles: ['devops'],
          isAdvancedPermission: true,
          parentKey: 'edit_add_inventory',
          groupKey: 'inventory',
        },
        {
          key: 'view_edit_storefront_inventory_settings',
          prettyName: 'Edit Storefront Inventory Settings',
          forbiddenRoles: ['devops'],
          isAdvancedPermission: true,
          parentKey: 'view_inventory',
          groupKey: 'inventory',
        },
        {
          key: 'view_edit_multilocation_inventory_settings',
          prettyName: 'Edit Multilocation Inventory Settings',
          forbiddenRoles: ['devops'],
          isAdvancedPermission: true,
          parentKey: 'view_inventory',
          groupKey: 'inventory',
        },
        {
          key: 'edit_pricing_cost',
          prettyName: 'Edit Pricing/Cost',
          forbiddenRoles: ['devops'],
          parentKey: 'view_inventory',
          groupKey: 'inventory',
        },
        {
          key: 'add_product_to_maintenance',
          prettyName: 'Add Product to Maintenance',
          forbiddenRoles: ['devops'],
          groupKey: 'inventory',
        },
        {
          key: 'view_serialized_tab_inventory',
          prettyName: 'View Serialized Tab',
          forbiddenRoles: ['devops'],
          groupKey: 'inventory',
        },
        {
          key: 'serialized_tab_interactions_inventory',
          prettyName: 'Serialized Tab Interactions',
          forbiddenRoles: ['devops'],
          groupKey: 'inventory',
          parentKey: 'view_serialized_tab_inventory',
        },
      ],
    },
    {
      prettyName: 'TapGoods Connect',
      keyName: 'tg_connect',
      permissions: [
        {
          key: 'make_shareable',
          prettyName: 'Make Inventory Shareable',
          forbiddenRoles: ['devops'],
        },
        {
          key: 'shop_connect',
          prettyName: 'Shop from Connect Inventory',
          forbiddenRoles: ['devops'],
        },
      ],
    },
    {
      prettyName: 'Operations',
      keyName: 'operations',
      permissions: [
        {
          key: 'create_edit_trucks',
          prettyName: 'Create/Edit Trucks',
          isAdvancedPermission: true,
          groupKey: 'operations',
        },
        {
          key: 'view_staff_wage_details',
          prettyName: 'View Staff Member Wage Details',
          isAdvancedPermission: true,
          groupKey: 'operations',
        },
        {
          key: 'add_stops_to_routes',
          prettyName: 'Add Stops to Routes',
          isAdvancedPermission: true,
          groupKey: 'operations',
        },
        {
          key: 'modify_start_times_on_routes',
          prettyName: 'Modify Start Times on Routes',
          isAdvancedPermission: true,
          groupKey: 'operations',
        },
        {
          key: 'edit_stop_addresses_on_routes',
          prettyName: 'Edit Addresses on Stops in Routes',
          isAdvancedPermission: true,
          groupKey: 'operations',
        },
        {
          key: 'modify_picklist_sorting',
          prettyName: 'Modify Pick List Sorting Style',
          isAdvancedPermission: true,
          groupKey: 'operations',
        },
        {
          key: 'reassign_trucks_on_routes',
          prettyName: 'Reassign Trucks to Existing Routes',
          isAdvancedPermission: true,
          groupKey: 'operations',
        },
        {
          key: 'bulk_actions_checkin_checkout',
          prettyName: 'Bulk Actions on Checking-In and Checking-Out pages',
          isAdvancedPermission: true,
          groupKey: 'operations',
        },
        {
          key: 'view_picklist_manager',
          prettyName: 'View Picklist Manager',
          isAdvancedPermission: false,
          groupKey: 'operations',
        },
        {
          key: 'edit_picklist_manager',
          prettyName: 'Edit Picklist Manager',
          isAdvancedPermission: false,
          groupKey: 'operations',
        },
      ],
    },
    {
      prettyName: 'Orders',
      keyName: 'orders',
      permissions: [
        {
          key: 'accept_quote',
          prettyName: 'Approve Pending Quotes',
          forbiddenRoles: ['devops'],
          groupKey: 'orders',
        },
        {
          key: 'create_rentals',
          prettyName: 'Create Orders',
          forbiddenRoles: ['devops'],
          groupKey: 'orders',
        },
        {
          key: 'view_reservations_quotes',
          prettyName: 'View Quotes',
          groupKey: 'orders',
        },
        {
          key: 'view_rentals',
          prettyName: 'View Orders',
          groupKey: 'orders',
        },
        {
          key: 'view_drafts',
          prettyName: 'View Drafts',
          forbiddenRoles: ['devops'],
          groupKey: 'orders',
        },
        {
          key: 'view_templates',
          prettyName: 'View Templates',
          forbiddenRoles: ['devops'],
          groupKey: 'orders',
        },
        {
          key: 'edit_rental_item_pricing',
          prettyName: 'Edit Item Pricing on Order',
          forbiddenRoles: ['devops'],
          groupKey: 'orders',
        },
        {
          key: 'view_pricing_billing',
          prettyName: 'View Pricing/Billing',
          forbiddenRoles: ['devops'],
          groupKey: 'orders',
        },
        {
          key: 'view_pick_list',
          prettyName: 'View Pick List(Check in/out)',
          groupKey: 'orders',
        },
        {
          key: 'add_fees',
          prettyName: 'Add Fees to Check in Inventory',
          forbiddenRoles: ['devops'],
          groupKey: 'orders',
        },
        {
          key: 'close_rentals',
          prettyName: 'Close Orders',
          forbiddenRoles: ['devops'],
          groupKey: 'orders',
        },
        {
          key: 'view_email_center',
          prettyName: 'View Email Center',
          forbiddenRoles: ['devops'],
          groupKey: 'orders',
        },
        {
          key: 'bulk_checking',
          prettyName: 'Bulk Checking In/Out',
          groupKey: 'orders',
        },
        // advanced permissions
        {
          key: 'edit_create_quotes',
          prettyName: 'Edit/Create Quotes',
          groupKey: 'orders',
          isAdvancedPermission: true,
        },
        {
          key: 'edit_orders',
          prettyName: 'Edit Orders',
          groupKey: 'orders',
          isAdvancedPermission: true,
        },
        {
          key: 'edit_create_drafts',
          prettyName: 'Edit/Create Drafts',
          groupKey: 'orders',
          isAdvancedPermission: true,
        },
        {
          key: 'edit_create_templates',
          prettyName: 'Edit/Create Templates',
          groupKey: 'orders',
          isAdvancedPermission: true,
        },
        {
          key: 'edit_discounts_orders_quotes',
          prettyName: 'Add/Remove Discounts from Orders/Quotes',
          groupKey: 'orders',
          isAdvancedPermission: true,
        },
        {
          key: 'bulk_actions_orders_quotes',
          prettyName: 'Bulk Actions on Orders/Quotes (All Order Statuses)',
          groupKey: 'orders',
          isAdvancedPermission: true,
        },
        {
          key: 'bulk_send_invoices',
          prettyName: 'Bulk Send Invoices',
          groupKey: 'orders',
          isAdvancedPermission: true,
        },
        {
          key: 'set_payment_date_outside_current',
          prettyName: 'Set Payment Date to non-current Date Value',
          groupKey: 'orders',
          isAdvancedPermission: true,
        },
        {
          key: 'close_order_with_ar_balance',
          prettyName: 'Close Order with Balance Due (AR Account)',
          groupKey: 'orders',
          isAdvancedPermission: true,
        },
        {
          key: 'close_order_with_balance',
          prettyName: 'Close Order with Balance Due (non-AR Account)',
          groupKey: 'orders',
          isAdvancedPermission: true,
        },
        {
          key: 'edit_closed_orders',
          prettyName: 'Edit Closed Orders',
          groupKey: 'orders',
          isAdvancedPermission: true,
        },
        {
          key: 'cancel_orders_quotes',
          prettyName: 'Cancel Orders/Quotes',
          groupKey: 'orders',
          isAdvancedPermission: true,
        },
        {
          key: 'delete_orders_quotes',
          prettyName: 'Delete Orders/Quotes',
          groupKey: 'orders',
          isAdvancedPermission: true,
        },
        {
          key: 'edit_contacts_orders_quotes',
          prettyName: 'Alter Contact(s) present on Orders/Quotes',
          groupKey: 'orders',
          isAdvancedPermission: true,
        },
        {
          key: 'edit_team_members_orders_quotes',
          prettyName: 'Alter Team Member Assignments on Orders/Quotes',
          groupKey: 'orders',
          isAdvancedPermission: true,
        },
        {
          key: 'view_download_documents_orders_quotes',
          prettyName: 'View/Download Documents on Orders/Quotes',
          groupKey: 'orders',
          isAdvancedPermission: true,
        },
        {
          key: 'edit_start_end_times_orders_quotes',
          prettyName: 'Edit Order/Quote Start and End Times',
          groupKey: 'orders',
          isAdvancedPermission: true,
        },
        {
          key: 'edit_delivery_details_orders_quotes',
          prettyName: 'Edit Delivery Details on Orders/Quotes',
          groupKey: 'orders',
          isAdvancedPermission: true,
        },
        {
          key: 'access_undo_last_status_change',
          prettyName:
            'Access to Undo Last Status Change Function (on History Tab)',
          groupKey: 'orders',
          isAdvancedPermission: true,
        },
        {
          key: 'duplicate_orders_quotes',
          prettyName: 'Duplicate Orders/Quotes',
          groupKey: 'orders',
          isAdvancedPermission: true,
        },
        {
          key: 'add_remove_temp_items_orders_quotes',
          prettyName: 'Add/Remove Temp Items on Orders/Quotes',
          groupKey: 'orders',
          isAdvancedPermission: true,
        },
        {
          key: 'edit_signature_requirements_orders_quotes',
          prettyName: 'Modify Signature Requirements on Orders/Quotes',
          groupKey: 'orders',
          isAdvancedPermission: true,
        },
        {
          key: 'edit_sales_tax_orders_quotes',
          prettyName: 'Modify Sales Tax Configuration on Orders/Quotes',
          groupKey: 'orders',
          isAdvancedPermission: true,
        },
        {
          key: 'edit_disclaimer_requirements_orders_quotes',
          prettyName: 'Modify Disclaimer Requirements on Orders/Quotes',
          groupKey: 'orders',
          isAdvancedPermission: true,
        },
        {
          key: 'edit_credit_card_fees_orders_quotes',
          prettyName: 'Modify Credit Card Fee Configuration on Orders/Quotes',
          groupKey: 'orders',
          isAdvancedPermission: true,
        },
        {
          key: 'edit_deposit_requirements_orders_quotes',
          prettyName: 'Modify Deposit Requirements on Orders/Quotes',
          groupKey: 'orders',
          isAdvancedPermission: true,
        },
        {
          key: 'edit_damage_waiver_fee_orders_quotes',
          prettyName: 'Modify Damage Waiver Fee on Orders/Quotes',
          groupKey: 'orders',
          isAdvancedPermission: true,
        },
        {
          key: 'book_all_product_units_available_for_given_period',
          prettyName: 'Book All Product Units Available for a Given Period',
          groupKey: 'orders',
          isAdvancedPermission: true,
        },
        {
          key: 'edit_signature_orders_quotes',
          prettyName: 'Overwrite/Remove Signature on Orders/Quotes',
          groupKey: 'orders',
          isAdvancedPermission: true,
        },
        {
          key: 'add_temporary_fees',
          prettyName: 'Add/Create Temporary Fees',
          groupKey: 'orders',
          isAdvancedPermission: true,
        },
      ],
    },
    {
      prettyName: 'Purchase Orders',
      keyName: 'purchase_orders',
      permissions: [
        {
          key: 'access_to_inventory_purchase_orders',
          prettyName: 'Access Inventory > Purchase Orders',
        },
        {
          key: 'submit_purchase_order_request_from_item_details',
          prettyName:
            'Submit Purchase Order Requests from Item Details > Purchase Orders section',
        },
        { key: 'create_purchase_orders', prettyName: 'Create Purchase Orders' },
        {
          key: 'view_open_purchase_orders',
          prettyName: 'View Open Purchase Orders',
        },
        {
          key: 'view_closed_purchase_orders',
          prettyName: 'View Closed Purchase Orders',
        },
        {
          key: 'view_purchase_order_details',
          prettyName: 'View Purchase Order Details',
        },
        { key: 'print_purchase_orders', prettyName: 'Print Purchase Order(s)' },
        {
          key: 'edit_purchase_order_payment_status',
          prettyName: 'Modify Purchase Order Payment Status',
        },
        {
          key: 'edit_purchase_order_status',
          prettyName: 'Modify Purchase Order Status',
        },
        {
          key: 'edit_purchase_order_vendor',
          prettyName: 'Modify Vendor info on Purchase Order',
        },
        {
          key: 'edit_purchase_order_completion_estimate',
          prettyName: 'Modify Purchase Order Completion Estimate',
        },
        {
          key: 'edit_purchase_order_description',
          prettyName: 'Modify Purchase Order Description',
        },
        {
          key: 'edit_purchase_order_inventory_section',
          prettyName: 'Modify inventory section on Purchase Order',
        },
        {
          key: 'edit_purchase_order_notes',
          prettyName: 'Modify Purchase Order Notes',
        },
        { key: 'cancel_purchase_order', prettyName: 'Cancel Purchase Orders' },
        {
          key: 'submit_purchase_order_check_in_all_inventory',
          prettyName: 'Access to Check In All Inventory button',
        },
        {
          key: 'edit_purchase_order_delivery_info',
          prettyName: 'Changing Deliver To information on Purchase Order',
        },
        {
          key: 'edit_purchase_order_delivery_cost',
          prettyName: 'Changing Delivery Costs on Purchase Order',
        },
        {
          key: 'edit_purchase_order_tax',
          prettyName: 'Modify Tax applied to Purchase Order',
        },
        {
          key: 'access_to_create_vendors_purchase_orders',
          prettyName: 'Create Vendors',
        },
      ],
    },
    {
      prettyName: 'Routing',
      keyName: 'routing',
      permissions: [
        { key: 'view_routing', prettyName: 'View Routing' },
        {
          key: 'assign_edit_routing',
          prettyName: 'Assign/Edit Routing',
          forbiddenRoles: ['devops'],
        },
      ],
      hideOnServices: true,
    },
    {
      prettyName: 'Staffing',
      keyName: 'staffing',
      permissions: [
        {
          key: 'view_edit_staffing',
          prettyName: 'View/Edit Staffing',
          forbiddenRoles: ['devops'],
        },
        {
          key: 'view_edit_nowsta',
          prettyName: 'View/Edit Nowsta Connection',
          groupKey: 'staffing',
          parentKey: 'view_edit_staffing',
          isAdvancedPermission: true,
        },
        {
          key: 'view_add_remove_staff',
          prettyName: 'View/Add/Remove Staff Members',
          groupKey: 'staffing',
          parentKey: 'view_edit_staffing',
          isAdvancedPermission: true,
        },
      ],
    },
    {
      prettyName: 'Commissions',
      keyName: 'commissions',
      permissions: [
        {
          key: 'view_edit_commissions',
          prettyName: 'View/Edit Commission Levels',
        },
        {
          key: 'view_edit_employee_commission_levels',
          prettyName: 'View/Edit Commission Levels for Employees',
        },
      ],
    },
    {
      prettyName: 'Reports',
      keyName: 'reports',
      permissions: [
        {
          key: 'pull_data_from_all_locations',
          prettyName: 'Pull Data From All Locations',
          forbiddenRoles: [
            'admin',
            'manager',
            'salesperson',
            'warehouse_manager',
            'devops',
          ],
        },
        {
          key: 'view_reports',
          prettyName: 'View Reports',
          forbiddenRoles: ['devops'],
        },
        {
          key: 'sync_rental_manually',
          prettyName: 'Sync Rental Manually',
          forbiddenRoles: [
            'manager',
            'salesperson',
            'warehouse_manager',
            'devops',
          ],
        },
        {
          key: 'view_advanced_reports',
          prettyName: 'View Advanced Reports',
          forbiddenRoles: ['devops'],
        },
        {
          key: 'view_powerbi_reports',
          prettyName: 'View PowerBi Reports',
          forbiddenRoles: ['devops'],
        },
        {
          key: 'access_quickbooks_connection_page_reports',
          prettyName: 'Access Quickbooks Connection Page',
          isAdvancedPermission: true,
          parentKey: '',
          groupKey: 'reports',
          forbiddenRoles: ['devops'],
        },
        {
          key: 'view_edit_gl_reports',
          prettyName: 'View/Edit General Ledger',
          isAdvancedPermission: true,
          parentKey: '',
          groupKey: 'reports',
          forbiddenRoles: ['devops'],
        },
        {
          key: 'export_reports',
          prettyName: 'Export Reports',
          isAdvancedPermission: true,
          parentKey: '',
          groupKey: 'reports',
          forbiddenRoles: ['devops'],
        },
      ],
    },
    {
      prettyName: 'Settings',
      keyName: 'settings',
      permissions: [
        {
          key: 'create_super_admin',
          prettyName: 'Create Super-Admin',
          forbiddenRoles: [
            'admin',
            'manager',
            'salesperson',
            'warehouse_manager',
            'devops',
          ],
        },
        {
          key: 'view_edit_settings',
          prettyName: 'View/Edit Settings',
          forbiddenRoles: ['devops'],
        },
        // advanced permissions
        {
          key: 'generate_bearer_token',
          prettyName: 'Generate Bearer Token',
          isAdvancedPermission: true,
          groupKey: 'settings',
          parentKey: 'view_edit_settings',
          forbiddenRoles: [
            'manager',
            'salesperson',
            'warehouse_manager',
            'devops',
          ],
        },
        {
          key: 'access_settings_location',
          prettyName: 'Access to Settings: Location',
          groupKey: 'settings',
          parentKey: 'view_edit_settings',
          isAdvancedPermission: true,
          forbiddenRoles: ['devops'],
        },
        {
          key: 'access_settings_inventory',
          prettyName: 'Access to Settings: Inventory',
          groupKey: 'settings',
          parentKey: 'view_edit_settings',
          isAdvancedPermission: true,
          forbiddenRoles: ['devops'],
        },
        {
          key: 'access_settings_financial',
          prettyName: 'Access to Settings: Financial Settings',
          groupKey: 'settings',
          parentKey: 'view_edit_settings',
          isAdvancedPermission: true,
          forbiddenRoles: ['devops'],
        },
        {
          key: 'access_settings_account',
          prettyName: 'Access to Settings: Account',
          groupKey: 'settings',
          parentKey: 'view_edit_settings',
          isAdvancedPermission: true,
          forbiddenRoles: ['devops'],
        },
        {
          key: 'access_settings_contacts',
          prettyName: 'Access to Settings: Contacts',
          groupKey: 'settings',
          parentKey: 'view_edit_settings',
          isAdvancedPermission: true,
          forbiddenRoles: ['devops'],
        },
        {
          key: 'access_settings_email',
          prettyName: 'Access to Settings: Email',
          groupKey: 'settings',
          parentKey: 'view_edit_settings',
          isAdvancedPermission: true,
          forbiddenRoles: ['devops'],
        },
        {
          key: 'access_settings_team',
          prettyName: 'Access to Settings: Team',
          groupKey: 'settings',
          parentKey: 'view_edit_settings',
          isAdvancedPermission: true,
          forbiddenRoles: ['devops'],
        },
        {
          key: 'access_settings_zapier',
          prettyName: 'Access to Settings: Zapier',
          groupKey: 'settings',
          parentKey: 'view_edit_settings',
          isAdvancedPermission: true,
          forbiddenRoles: ['devops'],
        },
        {
          key: 'access_settings_legal',
          prettyName: 'Access to Settings: Legal',
          groupKey: 'settings',
          parentKey: 'view_edit_settings',
          isAdvancedPermission: true,
          forbiddenRoles: ['devops'],
        },
        {
          key: 'access_settings_order',
          prettyName: 'Access to Settings: Order',
          groupKey: 'settings',
          parentKey: 'view_edit_settings',
          isAdvancedPermission: true,
          forbiddenRoles: ['devops'],
        },
        {
          key: 'access_settings_tg_connect',
          prettyName: 'Access to Settings: TapGoods Connect',
          groupKey: 'settings',
          parentKey: 'view_edit_settings',
          isAdvancedPermission: true,
          forbiddenRoles: ['devops'],
        },
        {
          key: 'access_settings_routing',
          prettyName: 'Access to Settings: Routing',
          groupKey: 'settings',
          parentKey: 'view_edit_settings',
          isAdvancedPermission: true,
          forbiddenRoles: ['devops'],
        },
        {
          key: 'access_settings_payments',
          prettyName: 'Access to Settings: Payments',
          groupKey: 'settings',
          parentKey: 'view_edit_settings',
          isAdvancedPermission: true,
          forbiddenRoles: ['devops'],
        },
        {
          key: 'access_settings_terminology',
          prettyName: 'Access to Settings: Terminology',
          groupKey: 'settings',
          parentKey: 'view_edit_settings',
          isAdvancedPermission: true,
          forbiddenRoles: ['devops'],
        },
        {
          key: 'access_settings_reports',
          prettyName: 'Access to Settings: Reports',
          groupKey: 'settings',
          parentKey: 'view_edit_settings',
          isAdvancedPermission: true,
          forbiddenRoles: ['devops'],
        },
        {
          key: 'access_settings_picklist',
          prettyName: 'Access to Settings: Picklist',
          groupKey: 'settings',
          parentKey: 'view_edit_settings',
          isAdvancedPermission: true,
          forbiddenRoles: ['devops'],
        },
      ],
    },
    {
      prettyName: 'Maintenance',
      keyName: 'maintenance',
      permissions: [
        {
          key: 'access_to_inventory_maintenance',
          prettyName: 'Access To Orders: Maintenance',
          isAdvancedPermission: true,
          parentKey: '',
          groupKey: 'maintenance',
        },
        {
          key: 'return_product_to_inventory_maintenance',
          prettyName: 'Returning Products to Inventory',
          isAdvancedPermission: true,
          parentKey: '',
          groupKey: 'maintenance',
        },
        {
          key: 'add_documents_to_products_maintenance',
          prettyName: 'Add Documents to Products in Maintenance',
          isAdvancedPermission: true,
          parentKey: '',
          groupKey: 'maintenance',
        },
        {
          key: 'create_work_orders_maintenance',
          prettyName: 'Create Work Orders',
          isAdvancedPermission: true,
          parentKey: '',
          groupKey: 'maintenance',
        },
        {
          key: 'remove_products_from_inventory_maintenance',
          prettyName: 'Remove Products from Inventory (Delete)',
          isAdvancedPermission: true,
          parentKey: '',
          groupKey: 'maintenance',
        },
      ],
    },
    {
      prettyName: 'Availability Calendar',
      keyName: 'availability_calendar',
      permissions: [
        {
          key: 'create_orders_on_blackout_days',
          prettyName: 'Create Orders on Blackout Days',
          isAdvancedPermission: true,
          parentKey: '',
          groupKey: 'availability_calendar',
        },
      ],
    },
    {
      prettyName: 'Admin',
      keyName: 'admin',
      permissions: [
        {
          key: 'create_edit_fees_admin',
          prettyName: 'Create/Edit Fees',
          isAdvancedPermission: true,
          parentKey: '',
          groupKey: 'admin',
        },
        {
          key: 'collect_payents_admin',
          prettyName: 'Collect Payments',
          isAdvancedPermission: true,
          parentKey: '',
          groupKey: 'admin',
        },
        {
          key: 'send_statements_admin',
          prettyName: 'Sending Statements',
          isAdvancedPermission: true,
          parentKey: '',
          groupKey: 'admin',
        },
        {
          key: 'gl_mapping_admin',
          prettyName: 'General Ledger Mapping',
          isAdvancedPermission: true,
          parentKey: '',
          groupKey: 'admin',
        },
        {
          key: 'access_admin_payment_terms',
          prettyName: 'Access Admin Payment Terms',
          isAdvancedPermission: true,
          parentKey: '',
          groupKey: 'admin',
        },
        {
          key: 'import_wizard',
          prettyName: 'Import Wizard',
          forbiddenRoles: [
            'devops',
            'salesperson',
            'manager',
            'warehouse_manager',
            'custom_role',
          ],
        },
        {
          key: 'import_wizard_revert',
          prettyName: 'Import Wizard Revert',
          forbiddenRoles: [
            'devops',
            'salesperson',
            'manager',
            'warehouse_manager',
            'custom_role',
          ],
        },
      ],
    },
  ];
};
export const convertScoreToRotation = (score) => {
  const degrees = Math.round(((score + 100) / 200) * 180);
  return degrees;
};
export const convertNpsScoreToColor = (score) => {
  const postiveScore = Math.round((score + 100) / 20);
  return npsColors[postiveScore];
};

export const urlPrefixForBusinessTier = {
  all: 'rentals',
  services: 'service',
  demo: 'demo',
  archived_all: 'archivedRentals',
  archived_services: 'archivedServices',
};

export const softwareTierForUrlPrefix = {
  rentals: 'all',
  service: 'services',
  demo: 'demo',
};

export const displayBusinessTier = {
  all: 'TapGoods PRO Rentals',
  services: 'TapGoods PRO Services',
};

export const displayBusinessType = {
  all: 'Rental Businesses',
  services: 'Service Businesses',
  archived_all: 'Archived Rentals',
  archived_services: 'Archived Services',
  demo: 'Demo Businesses',
};

export const displayBusinessTierInShort = {
  all: 'Rentals',
  services: 'Services',
};

export const displayRentalStatus = {
  quote: 'Quote',
  reservation: 'Reservation',
  in_use: 'Checked-Out',
  checked_in: 'Checked-In',
  closed: 'Closed',
  cancelled: 'Cancelled',
  draft: 'Draft',
  template: 'Template',
};

export const displayNowstaRentalStatus = {
  pending: 'Pending',
  quote: 'Quotes',
  reservation: 'Reservations',
  in_use: 'Checked Out',
  checked_in: 'Checked In',
  closed: 'Closed',
};

export const displayActiveRentalStatus = {
  quote: 'Quote',
  reservation: 'Reservation',
  in_use: 'Checked-Out',
  checked_in: 'Picked',
};

export const displayRentalSource = {
  standard: 'Standard',
  connect: 'Connect',
  storefront: 'Storefront',
  marketplace: 'Marketplace',
  multilocation: 'Multilocation',
  reventals: 'Reventals',
};

export const displayCheckedOutStatus = {
  alert: 'Alerts',
  full: 'Fully Picked',
  partial: 'Partially Picked',
  none: 'Nothing Picked',
};

export const displayCheckedInStatus = {
  alert: 'Alerts',
  full: 'Fully Checked In',
  partial: 'Partially Checked In',
  none: 'Nothing Checked In',
};

export const displayTransactionStatus = {
  payment: 'Payment',
  partial_refund: 'Partial Refund',
  refund: 'Refund',
  approved: 'Approved',
};

export const displayPaymentType = {
  credit_card: 'Credit Card',
  cash: 'Cash',
  check: 'Check',
  bank: 'Bank',
};

export const displayDocumentType = {
  rental_agreement: 'Order Agreement',
  insurance: 'Insurance',
  legal_documents: 'Legal Documents',
  other: 'Other',
  waiver_forms: 'Waiver Forms',
  customer_documents: 'Customer Documents',
  contracts: 'Contracts',
  customer_tax_exempt_forms: 'Customer Tax Exempt Forms',
  company_tax_exempt_forms: 'Company Tax Exempt Forms',
  customer_rental_agreements: 'Customer Order Agreements',
  other_customer_documents: 'Other Customer Documents',
  venue_floor_plan: 'Venue Floorplan',
  parking_map: 'Parking Map',
  delivery_map: 'Delivery Map',
  photos: 'Photos',
  driver_license: 'Driver License',
  certificate_of_insurance: 'Certificate of Insurance (COI)',
  signed_agreement: 'Signed Agreements',
  signed_checkout: 'Signed Checkout',
  signed_checkin: 'Signed Checkin',
  floor_plan: 'Floor Plan',
};

export const displayContacts = {
  customer: 'Customer',
  company: 'Company',
  venue: 'Venue',
  rental: 'Order (Primary Contact)',
};

export const displayDocumentStatus = {
  active: 'Active',
  expired: 'Expired',
};

export const displayRoles = {
  owner: 'Super Admin',
  admin: 'Admin',
  manager: 'Manager',
  devops: 'Warehouse',
  warehouse_manager: 'Warehouse Manager',
  salesperson: 'Team Member',
};

export const customerTitles = {
  contacts: 'Contacts',
  customers: 'Customers',
  venues: 'Venues',
  companies: 'Companies',
};
export const singleCustomerTitles = {
  contacts: 'Contact',
  customers: 'Customer',
  venues: 'Venue',
  companies: 'Company',
};
export const apiConnectionsForContact = {
  contacts: 'contacts',
  customers: 'customer_location_relationships',
  venues: 'venues',
  companies: 'companies',
};

export const displaySingularInventory = {
  items: 'item',
  addons: 'purchase',
  addOns: 'purchase',
  accessories: 'accessory',
  bundles: 'bundle',
  commodities: 'item',
};
export const capitalSingularInventory = {
  items: 'Item',
  addons: 'Purchase',
  addOns: 'Purchase',
  accessories: 'Accessory',
  bundles: 'Bundle',
  commodities: 'Item',
};
export const capitalPluralInventory = {
  items: 'Items',
  addons: 'Purchases',
  addOns: 'Purchases',
  accessories: 'Accessories',
  bundles: 'Bundles',
  commodities: 'Items',
  servicesProduct: 'Services',
  servicesAccessories: 'Services',
  servicesAddOns: 'Services',
  servicesBundles: 'Services',
};
export const capitalHybridInventory = {
  items: 'Item(s)',
  addons: 'Purchase(s)',
  addOns: 'Purchase(s)',
  accessories: 'Accessory(s)',
  bundles: 'Bundle(s)',
  commodities: 'Item(s)',
};

export const displayPluralInventory = {
  items: 'items',
  addons: 'purchases',
  accessories: 'accessories',
  bundles: 'bundles',
  commodities: 'items',
};

export const apiConnections = {
  items: 'products',
  addons: 'add_ons',
  addOn: 'add_ons',
  addOns: 'add_ons',
  product: 'products',
  accessory: 'accessories',
  accessories: 'accessories',
  bundles: 'bundles',
  bundle: 'bundles',
  inventories: 'inventories',
  inventory: 'inventory',
  commodities: 'products',
  commodity: 'products',
  contacts: 'customers',
  customers: 'customers',
  venues: 'venues',
  companies: 'companies',
  maintenanceRecords: 'maintenance_records',
  servicesProduct: 'staff_roles',
  servicesAccessories: 'staff_roles',
  servicesAddOns: 'staff_roles',
  servicesBundles: 'staff_roles',
};

export const apiToSingularConnections = {
  items: 'product',
  addons: 'addOn',
  addOn: 'addOn',
  addOns: 'addOn',
  accessory: 'accessory',
  accessories: 'accessory',
  bundles: 'bundle',
  bundle: 'bundle',
  inventories: 'inventory',
  inventory: 'inventory',
  commodities: 'commodity',
  staffs: 'staff',
};

export const apiToSingularRoot = {
  items: 'product',
  addons: 'add_on',
  addOn: 'add_on',
  addOns: 'add_on',
  accessory: 'accessory',
  accessories: 'accessory',
  bundles: 'bundle',
  bundle: 'bundle',
  inventories: 'inventory',
  inventory: 'inventory',
  commodities: 'commodity',
  servicesProduct: 'service',
  servicesAccessories: 'service',
  servicesAddOns: 'service',
  servicesBundles: 'service',
};

export const apiToClassNames = {
  items: 'Product',
  addons: 'AddOn',
  addOn: 'AddOn',
  addOns: 'AddOn',
  accessory: 'Accessory',
  accessories: 'Accessory',
  bundles: 'Bundle',
  bundle: 'Bundle',
  commodities: 'Commodity',
};

export const displayApiClasssNames = {
  Product: 'items',
  Accessory: 'accessories',
  AddOn: 'addons',
};

export const supplierTypes = {
  items: 'product',
  addons: 'add_on',
  accessories: 'accessory',
};

export const suppliableTypes = {
  items: 'Product',
  addons: 'AddOn',
  accessories: 'Accessory',
};

export const apiReceiving = {
  items: 'products',
  addons: 'addOns',
  addOns: 'addOns',
  accessories: 'accessories',
  bundles: 'bundles',
  inventories: 'inventories',
  maintenanceRecords: 'maintenanceRecords',
  commodities: 'products',
  item: 'product',
  addon: 'addOn',
  addOn: 'addOn',
  accessory: 'accessory',
  bundle: 'bundle',
  maintenanceRecord: 'maintenanceRecord',
  commodity: 'product',
  servicesProduct: 'staffRoles',
  servicesAccessories: 'staffRoles',
  servicesAddOns: 'staffRoles',
  servicesBundles: 'staffRoles',
};

export const apiReceivingSingle = {
  items: 'product',
  addons: 'addOn',
  addOns: 'addOn',
  accessories: 'accessory',
  bundles: 'bundle',
  inventories: 'inventory',
  maintenanceRecords: 'maintenanceRecord',
  commodities: 'commodity',
  servicesProduct: 'product',
  servicesAccessories: 'product',
  servicesAddOns: 'product',
  servicesBundles: 'product',
};

export const apiRentalReceiving = {
  items: 'rentalItems',
  addons: 'rentalAddOns',
  addOns: 'rentalAddOns',
  accessories: 'rentalAccessories',
  item: 'rentalItems',
  addon: 'rentalAddOns',
  addOn: 'rentalAddOns',
  accessory: 'rentalAccessories',
  bundles: 'rentalBundles',
};

export const typeToRental = {
  items: 'rental_item',
  addons: 'rental_add_on',
  addOns: 'rental_add_on',
  accessories: 'rental_accessory',
  bundles: 'rental_bundle',
};
export const typeToRentalPickList = {
  items: 'pick_list_item',
  addons: 'pick_list_add_on',
  addOns: 'pick_list_add_on',
  accessories: 'pick_list_accessory',
};
export const typeToRentalPlural = {
  items: 'rental_items',
  addons: 'rental_add_ons',
  addOns: 'rental_add_ons',
  accessories: 'rental_accessories',
  bundles: 'rental_bundles',
};
export const typeToRentalPluralPickList = {
  items: 'pick_list_items',
  addons: 'pick_list_add_ons',
  addOns: 'pick_list_add_ons',
  add_ons: 'pick_list_add_ons',
  accessories: 'pick_list_accessories',
};
export const getInventoryId = {
  items: 'productId',
  products: 'productId',
  addons: 'addOnId',
  addOns: 'addOnId',
  accessories: 'accessoryId',
  bundles: 'bundleId',
};

export const emailTypes = {
  RentalMailer: {
    email_summary: 'Summary',
    quote_received: 'Quote',
    quote_approved: 'Quote Approved',
    quote_approved_connect: 'Quote Approved - Connect',
    quote_denied: 'Quote Denied',
    quote_denied_connect: 'Quote Denied - Connect',
    quote_pending: 'Quote Pending',
    email_invoice: 'Invoice',
    email_invoice_without_discounts: 'Invoice without Discounts',
    email_invoice_with_discounts: 'Invoice with Discounts (Internal Use Only)',
    email_reserved: 'Reservation',
    quote_cancelled: 'Quote Cancelled',
    payment_reminder: 'Payment Reminder',
    email_closed: 'Thank You',
    staff_reminder: 'Staff Reminder',
    customer_welcome: 'Customer Welcome',
    signature_confirmation: 'Signature Confirmation',
    email_request: 'Action Requested',
    action_request: 'Action Requested',
    autobooked: 'Autobooked',
    autobooked_business: 'Autobooked Business',
    nps_email: 'Customer Survey',
    custom_email: 'Custom',
    reminder_email: 'Order Reminder',
    final_balance_due: 'Final Balance Due',
    expire_email: 'Quote Expiration',
    email_request_template: 'Action Request',
  },
  TransactionMailer: {
    payment_received: 'Payment Confirmation',
    payment_received_reservation: 'Payment Confirmation',
    receipt: 'Receipt',
  },
  ProposalMailer: {
    proposal: 'Proposal',
  },
  RecurringPaymentMailer: {
    scheduled: 'Recurring Payment',
  },
  ScheduledMailer: {
    nps_email: 'Customer Survey',
    reminder_email: 'Order Reminder',
    final_balance_due: 'Final Balance Due',
    expire_email: 'Quote Expiration',
  },
};

export const emailTypesForRails = {
  quote: 'quote_received',
  invoice: 'email_invoice',
  summary: 'email_summary',
};

export const emailTypesForSettings = {
  reminder_email: 'reminderEmail',
  expire_email: 'expireEmail',
  email_summary: 'summaryEmail',
  quote_received: 'quoteEmail',
  email_invoice: 'invoiceAddress',
  payment_reminder: 'paymentReminderEmail',
  staff_reminder: 'staffReminder',
  receipt: 'paymentConfirmationEmail',
  proposal: 'proposalEmail',
  email_closed: 'closedEmail',
  nps_email: 'npsEmail',
  final_balance_due_without_auto_bill_email:
    'finalBalanceDueWithoutAutoBillEmail',
  final_balance_due_with_auto_bill_email: 'finalBalanceDueWithAutoBillEmail',
  action_request: 'actionRequestEmail',
  email_invoice_without_discounts: 'invoiceAddress',
  email_invoice_with_discounts: 'invoiceAddress',
};

export const tutorialTitles = {
  addItem: [
    'Click to add new item',
    'Name item',
    'Add item description',
    'Select Tag',
    'Select Department',
    'Insert quantity and price',
    'Add photo (optional)',
    'Press submit',
  ],
  addCustomer: [
    'Click to add new customer',
    'Specify first name',
    'Specify last name',
    'Specify email',
    'Press submit',
  ],
  createRental: [
    'Click to add new order',
    'Select a start date',
    'Select an end date',
    'Press "Select Customers"',
    'Select a customer (right side)',
    'Select a delivery type (lower left side)',
    'Press "Items & Staffing" at the top',
    'Press "Add Inventory to Order"',
    'Enter a quantity of an item to add',
    'Press "Add Inventory to Order"',
    'Press Create Quote',
  ],
};

export const revenueCostMapper = {
  less_than_1mm: 398,
  '1mm_to_2mm': 598,
  '2mm_to_3mm': 898,
  '3mm_to_5mm': 1398,
  '5mm_to_10mm': 2598,
  '10mm_to_15mm': 3298,
  more_than_15mm: 3298,
};

export const displayRevenueEstimate = {
  less_than_1mm: 'Less than $1MM',
  '1mm_to_2mm': '$1-2MM',
  '2mm_to_3mm': '$2-3MM',
  '3mm_to_5mm': '$3-5MM',
  '5mm_to_10mm': '$5-10MM',
  '10mm_to_15mm': '$10-15MM',
  more_than_15mm: 'More than $15MM',
};

export const displayPreferredDeliveryPickupWindow = {
  early_bird: 'Early Bird 4a-8a',
  morning: 'Morning 8a-12p',
  afternoon: 'Afternoon 12p-4p',
  evening: 'Evening 4p-8p',
  night_owl: 'Night Owl 8p-12a',
  early: 'Early 7a-10a',
  midday: 'Midday 10a-2p',
  late: 'Late 10a-2p',
};

export const displayMarketplaceCustomPageStatus = (status, live) => {
  switch (status) {
    case 'draft':
      return 'Draft';
    case 'pending':
      return 'Waiting for Approval';
    case 'denied':
      return 'Denied';
    case 'approved':
      if (live) {
        return 'Approved and Live!';
      } else {
        return 'Approved, Make Live! ';
      }
    default:
      return 'Waiting for Approval';
  }
};

export const displayRevenueTypes = {
  subscription: 'Subscription Fee',
  fee: 'Business Fee',
  refund: 'Business Refund',
  propay: 'ProPay (Net Revenue)',
  marketplace: 'Marketplace Fee',
};

export const displaySupportLevel = {
  0: 'Lite',
  1: 'Standard',
  2: 'Select',
};

export const recognizeOptions = {
  order_start: 'Day the order starts.',
  order_end: 'Day the order ends.',
  order_close: 'Day the order closes.',
  order_full: 'Day the order is fully paid.',
  payment_receive: 'Day payment is received.',
};
export const displayRentalPickupType = {
  drop_off: 'Drop Off',
  pick_up: 'Pick Up',
};

export const displayPaymentDaysOption = {
  before: 'Before',
  after: 'After',
};

export const displayPaymentDaysType = {
  event_start: 'Order Start',
  event_creation: 'Order Creation',
  event_end: 'Order End',
  due_date: 'Final Balance Due',
  off: 'Off',
};

export const displayRentalShippingType = {
  ship: 'Ship',
  return: 'Return',
};

export const rentalItemListPerPage = 25;
